<template>
  <div class="card-container">
    <div class="head">問題 {{ currentQuestion.number }}</div>
    <div class="title-container">{{ currentQuestion.titleZh }}</div>
    <div class="options-container">
      <div
        class="option"
        v-for="(item, index) in currentQuestion.options"
        :key="index"
      >
        <div
          @click="onOptionSelect(item.value)"
          class="option-btn"
          :class="{
            'option-btn--selected': isOptionSelected(item.value)
          }"
        >
          {{ item.textZh }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.head {
  font-size: 14px;
}

.title-container {
  font-weight: 400;
  margin: 2px 0px 8px 0px;
}

.card-container {
  border: 1px solid #fff;
  border-radius: 5px;
  margin: 15px 0px;
  padding: 15px;
}

.options-container {
  display: flex;

  .option {
    padding: 5px;

    .option-btn {
      border-radius: 20px;
      text-align: center;
      height: 22px;
      line-height: 22px;
      width: 38px;
      padding: 5px;
      font-size: 14px;

      background-color: #fff;
      color: #000;
      cursor: pointer;
    }

    .option-btn--selected {
      border: 0.5px solid #fff;
      background-color: transparent;
      color: #fff;
    }
  }
}
</style>

<script>
export default {
  name: "QuestionCard",
  props: {
    questionNumber: Number
  },
  data: function() {
    return {
      selectedValue: 0
    };
  },
  methods: {
    onOptionSelect(value) {
      this.selectedValue = value;
      this.$store.dispatch("upsertAnsweredQuestion", {
        questionNumber: this.questionNumber,
        selectedValue: value
      });
    },
    // onOptionCancel() {
    //   this.selectedValue = 0;
    // },
    isOptionSelected(value) {
      return this.selectedValue === value;
    }
  },
  computed: {
    currentQuestion() {
      return this.$store.getters.questionByNumber(Number(this.questionNumber));
    }
  },
  mounted() {
    // this.currentQuestion = this.$store.getters.questionByNumber(3);
  }
};
</script>
